import { mapHelper } from "@/utils/common.js";
const timeDuring = [
  {
    value: 1,
    label: "上午",
  },
  {
    value: 2,
    label: "下午",
  },
];
const { map: timeDuringMap, setOps: setTimeDuringOps } =
  mapHelper.setMap(timeDuring);

const classStatus = [
  {
    value: 1,
    label: "已结束",
  },
  {
    value: 2,
    label: "进行中",
  },
  {
    value: 3,
    label: "待开始",
  },
];
const { map: classStatusMap, setOps: setClassStatusOps } =
  mapHelper.setMap(classStatus);
//预约形式
const reservationList = [
  {
    value: 1,
    label: "按课程预约",
  },
  {
    value: 2,
    label: "按时段预约",
  },
];
const { map: reservationListMap, setOps: reservationListOps } =
  mapHelper.setMap(reservationList);
// 	星期
const weekArr = [
  {
    value: "1",
    label: "星期一",
  },
  {
    value: "2",
    label: "星期二",
  },
  {
    value: "3",
    label: "星期三",
  },
  {
    value: "4",
    label: "星期四",
  },
  {
    value: "5",
    label: "星期五",
  },
  {
    value: "6",
    label: "星期六",
  },
  {
    value: "7",
    label: "星期日",
  },
];
const { map: weekArrMap, setOps: weekArrOps } = mapHelper.setMap(weekArr);
//签到状态
const signStatus = [
  {
    value: 0,
    label: "待签到",
  },
  {
    value: 1,
    label: "待签出",
  },
  {
    value: 2,
    label: "缺席",
  },
  {
    value: 3,
    label: "未开始",
  },
  {
    value: 4,
    label: "已签",
  },
];
const { map: signStatusMap, setOps: signStatusOps } =
  mapHelper.setMap(signStatus);

export {
  timeDuringMap,
  setTimeDuringOps,
  classStatusMap,
  setClassStatusOps,
  reservationListOps,
  reservationListMap,
  weekArrOps,
  weekArrMap,
  signStatusOps,
  signStatusMap,
};
